import "core-js/modules/es.array.push.js";
import { imgDetails, configFile, signGoodsList, orderTask, getVerifyCode, toVerifyCode, refreshTask, information } from '@/assets/js/api';
import { imgFilter, jumpPage, servis, filterStopTime } from '@/assets/js/index';
import { reactive, toRefs, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import verifyCode from '@/components/verifyCode';
import { showFailToast, showSuccessToast } from 'vant';
import { globals } from '@/assets/js/global';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'index',
  inject: ['$back', '$imgFilter'],
  components: {
    verifyCode
  },
  setup() {
    const {
      state,
      dispatch,
      commit
    } = useStore();
    const {
      push
    } = useRouter();
    const data = reactive({
      isLog: computed(e => state.token),
      grid: [{
        name: '充值',
        ico: require('@/assets/img/gird/1.png'),
        path: '/recharge'
      }, {
        name: '提现',
        ico: require('@/assets/img/gird/2.png'),
        path: '/withdraw'
      }, {
        name: '团队报表',
        ico: require('@/assets/img/gird/3.png'),
        path: '/team'
      }, {
        name: '账户明细',
        ico: require('@/assets/img/gird/4.png'),
        path: '/account'
      }, {
        name: '个人信息',
        ico: require('@/assets/img/gird/5.png'),
        path: '/info'
      }, {
        name: '积分商城',
        ico: require('@/assets/img/gird/6.png'),
        path: '/integ'
      }, {
        name: '我的消息',
        ico: require('@/assets/img/gird/7.png'),
        path: '/msg'
      }, {
        name: '联系客服',
        ico: require('@/assets/img/gird/8.png'),
        path: 'server'
      }],
      isReady: false,
      show: false,
      lottery_switch: '',
      signMsg: '',
      banner: [],
      marquee_placard: '',
      pop_ups_placard: '',
      service: '',
      // hide_inside_switch: "",
      // token: sessionStorage.getItem('token'),
      token: localStorage.getItem('token'),
      myInfo: computed(() => state.info),
      isOpen: localStorage.getItem('is_open'),
      list: [],
      loading: false,
      finished: false,
      page: 0,
      orderList: [],
      checked: '',
      showCenter: false,
      showCenterPlatform: false,
      curIdentifyCode: '',
      buyType: '',
      verifyCode: '',
      platform: 0,
      showMsg: false,
      popMsg: '',
      showAD: false,
      popAD: '',
      checkedAD: '',
      jump: '',
      showBuyTimes: false,
      buyTimesNum: 100,
      isBatch: false,
      value: ''
    }); // 数据
    const methods = {
      commit,
      servis,
      push,
      toServis(path) {
        if (path === 'server') {
          servis();
        } else {
          push(path);
        }
      },
      toJump(val, type) {
        if (val) {
          if (type === '1') {
            jumpPage(val);
          } else if (type === '0') {
            push(val);
          }
        }
      },
      getImg(img) {
        return imgFilter(img);
      },
      /* --------------批量下单-------------- */
      batchOrder() {
        if (data.myInfo.can_doing === '0') {
          showFailToast('抱歉！您的做单功能已被暂停，请您联系推荐人处理。');
          return;
        }
        if (parseFloat(data.myInfo.can_use) === 0) {
          showFailToast('暂无权限，请联系客服~');
          return;
        }
        if (parseFloat(data.myInfo.account.user_money) < 5000) {
          showFailToast('余额不足，请充值后重试！');
          return;
        }
        if (parseFloat(data.myInfo.memberLevel.level) <= 0) {
          showFailToast('您还不是资深会员，暂无法使用该功能~');
          return;
        }
        data.showBuyTimes = true;
        data.isBatch = true;
      },
      async load() {
        if (data.token) {
          const orderRes = await orderTask({
            page: 1,
            pages_size: 10
          });
          if (orderRes.code === 200) {
            if (orderRes.data.length !== 0) {
              data.checked = orderRes.data[0].text;
              data.orderList = orderRes.data;
            }
          }
        }
        const banner = await imgDetails({
          pid: '1'
        });
        if (banner.code === 200) {
          data.banner = banner.data;
        }
        const list = await signGoodsList({
          page: data.page += 1,
          lang: 'cn'
        });
        if (list.code === 200) {
          // data.loading = false
          // this.signList = list.data;
          data.list.push.apply(data.list, list.data);
        }
        const isOffAd = localStorage.getItem('offAD');
        const nowDate = new Date();
        const year = nowDate.getFullYear();
        const month = nowDate.getMonth() + 1 < 10 ? '0' + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
        const day = nowDate.getDate();
        const nowTime = year + '-' + month + '-' + day;
        const offADTime = localStorage.getItem('offADTime');
        const res = await configFile({
          field: ['marquee_placard', 'customer_service_link', 'lottery_switch', 'pop_ups_placard'
          // "hide_inside_switch"
          ]
        });
        if (res.code === 200) {
          data.marquee_placard = res.data.marquee_placard;
          data.pop_ups_placard = res.data.pop_ups_placard;
          data.service = res.data.customer_service_link;
          data.lottery_switch = res.data.lottery_switch;
          if (isOffAd && offADTime === nowTime) {
            data.showAD = false;
            return;
          } else {
            if (data.checkedAD) {
              localStorage.setItem('offAD', true);
              localStorage.setItem('offADTime', nowTime);
            } else {
              if (res.data.pop_ups_placard !== '') {
                data.showAD = true;
              } else {
                data.showAD = false;
                return;
              }
              localStorage.setItem('offAD', false);
              localStorage.setItem('offADTime', '');
            }
          }
          data.popAD = globals.$imgUrl + res.data.pop_ups_placard;
          data.jump = res.data.lottery_switch;
        }
        // 数据全部加载完成
        // if (list.data.length < 10) {
        //   data.finished = true
        // }
      },
      filterStopTime,
      async reload() {
        const res = await refreshTask();
        if (res.code === 200) {
          showSuccessToast('刷新成功');
          data.page = 0;
          data.checked = '';
          data.list = [];
          // methods.onLoad()
          methods.load();
        }
      },
      async checkPlatform() {
        data.showCenterPlatform = false;
        methods.getCode();
      },
      async getCode(type) {
        data.showBuyTimes = false;
        data.showCenter = true;
        const res = await getVerifyCode();
        if (res.code === 200) {
          data.curIdentifyCode = res.data;
        }
      },
      closePop() {
        data.buyTimesNum = 100;
        data.value = '';
      },
      saveType(type, status) {
        if (data.myInfo.can_doing === '0') {
          showFailToast('抱歉！您的做单功能已被暂停，请您联系推荐人处理。');
          return;
        }
        if (methods.filterStopTime(data.myInfo.vip_stop_time) === 0) {
          commit('UPDATEADDVIP', true);
        } else {
          if (type === 1) {
            showFailToast('无权限');
            return;
          }
          data.buyType = type;
          // data.showCenterPlatform = true
          if (status === 'batch') {
            if (data.value && data.value < 100) {
              showFailToast('下单量必须大于100');
              return;
            }
            data.isBatch = true;
            if (data.value) {
              data.buyTimesNum = data.value;
            }
          } else {
            data.isBatch = false;
          }
          methods.getCode();
        }
      },
      async buyOrderFun() {
        // if (methods.filterStopTime(data.myInfo.vip_stop_time) === 0) {
        //   commit('UPDATEADDVIP', true)
        // } else {
        const res = await toVerifyCode({
          code: data.verifyCode
        });
        if (res.code === 200) {
          if (data.isBatch) {
            push({
              path: '/buyInfo',
              query: {
                taskName: data.checked,
                type: data.buyType,
                platform: data.platform,
                buyTimesNum: data.buyTimesNum
              }
            });
          } else {
            push({
              path: '/buyInfo',
              query: {
                taskName: data.checked,
                type: data.buyType,
                platform: data.platform,
                buyTimesNum: 2
              }
            });
          }
        } else {
          methods.getCode();
        }
        // }
      },
      async getPopMsg() {
        const res = await information();
        if (res.code === 200) {
          if (res.data !== '') {
            data.popMsg = res.data;
            data.showMsg = true;
          } else {
            data.showMsg = false;
          }
        }
      },
      offAD() {
        const isOffAd = localStorage.getItem('offAD');
        const nowDate = new Date();
        const year = nowDate.getFullYear();
        const month = nowDate.getMonth() + 1 < 10 ? '0' + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
        const day = nowDate.getDate();
        const nowTime = year + '-' + month + '-' + day;
        const offADTime = localStorage.getItem('offADTime');
        if (isOffAd && offADTime === nowTime) {
          data.showAD = false;
        } else {
          if (data.checkedAD) {
            localStorage.setItem('offAD', true);
            localStorage.setItem('offADTime', nowTime);
          } else {
            localStorage.setItem('offAD', false);
            localStorage.setItem('offADTime', '');
          }
        }
      },
      jumpUrl() {
        push(data.jump);
        // window.location.href = this.jump
        // window.open(this.jump)
      }
    }; // 事件
    onMounted(() => {
      methods.load();
      if (data.token) {
        dispatch('ACTgetMyinfo');
        methods.getPopMsg();
      }
    });
    return {
      ...toRefs(data),
      ...methods
    };
  }
};