export default {
  '立即注册，解锁更多精彩内容': '立即注册，解锁更多精彩内容',
  '注册/登录': '注册/登录',
  首页: '首页',
  // 保险理财: '保险理财',
  保险理财: '理财',
  运单任务: '运单任务',
  订单列表: '订单列表',
  我的: '我的',
  权限不足: '权限不足',
  '标识符不能为空。': '标识符不能为空。',
  ID不能为空: 'ID不能为空',
  '抽奖功能已关闭！': '抽奖功能已关闭！',
  '您今天的抽奖次数已用完！': '您今天的抽奖次数已用完！',
  '提交成功！': '提交成功！',
  '提交失败！': '提交失败！',
  类型不正确: '类型不正确',
  '请您先实名认证后再继续操作！': '请您先实名认证后再继续操作！',
  '绑定银行卡失败,请稍后再试': '绑定银行卡失败,请稍后再试',
  请输入实名认证用户下的真实银行卡号码: '请输入实名认证用户下的真实银行卡号码',
  '该银行卡已被绑定。': '该银行卡已被绑定。',
  绑定成功: '绑定成功',
  '该银行卡不存在！': '该银行卡不存在！',
  安全密码不能为空: '安全密码不能为空',
  '安全密码错误！': '安全密码错误！',
  '解绑成功！': '解绑成功！',
  修改成功: '修改成功',
  '旧密码不能为空。': '旧密码不能为空。',
  '新密码不能为空。': '新密码不能为空。',
  '确定新密码不能为空。': '确定新密码不能为空。',
  您输入的俩次密码不一致: '您输入的俩次密码不一致',
  '旧密码只能包含至多20个字符。': '旧密码只能包含至多20个字符。',
  '新密码只能包含至多20个字符。': '新密码只能包含至多20个字符。',
  '确定新密码只能包含至多20个字符。': '确定新密码只能包含至多20个字符。',
  '旧密码只能包含至少6个字符。': '旧密码只能包含至少6个字符。',
  '新密码只能包含至少6个字符。': '新密码只能包含至少6个字符。',
  '确定新密码只能包含至少6个字符。': '确定新密码只能包含至少6个字符。',
  旧安全密码错误: '旧安全密码错误',
  '旧安全密码不能为空。': '旧安全密码不能为空。',
  '新安全密码不能为空。': '新安全密码不能为空。',
  '确定新安全密码不能为空。': '确定新安全密码不能为空。',
  '您输入的俩次安全密码不一致。': '您输入的俩次安全密码不一致。',
  '旧安全密码只能包含至多20个字符。': '旧安全密码只能包含至多20个字符。',
  '新安全密码只能包含至多20个字符。': '新安全密码只能包含至多20个字符。',
  '确定新安全密码只能包含至多20个字符。': '确定新安全密码只能包含至多20个字符。',
  '旧安全密码只能包含至少6个字符。': '旧安全密码只能包含至少6个字符。',
  '新安全密码只能包含至少6个字符。': '新安全密码只能包含至少6个字符。',
  '确定新安全密码只能包含至少6个字符。': '确定新安全密码只能包含至少6个字符。',
  '您已完成实名认证审核，请勿重复提交！': '您已完成实名认证审核，请勿重复提交！',
  '实名认证失败,请稍后再试': '实名认证失败,请稍后再试',
  请输入真实的姓名和身份证号码: '请输入真实的姓名和身份证号码',
  操作成功: '操作成功',
  请输入正确的手机号码: '请输入正确的手机号码',
  '此手机号已存在。': '此手机号已存在。',
  '此身份证号码已存在。': '此身份证号码已存在。',
  找不到该公告: '找不到该公告',
  '您的申请正在审核中，请耐心等待！': '您的申请正在审核中，请耐心等待！',
  '提交成功,请等待管理员审核！': '提交成功,请等待管理员审核！',
  '系统繁忙,请稍后再试': '系统繁忙,请稍后再试',
  '真实姓名不能为空。': '真实姓名不能为空。',
  '证件正面不能为空。': '证件正面不能为空。',
  '证件反面不能为空。': '证件反面不能为空。',
  '真实姓名只能包含至多50个字符。': '真实姓名只能包含至多50个字符。',
  '证件号码只能包含至多50个字符。': '证件号码只能包含至多50个字符。',
  '证件正面只能包含至多255个字符。': '证件正面只能包含至多255个字符。',
  '证件反面只能包含至多255个字符。': '证件反面只能包含至多255个字符。',
  '领取失败！': '领取失败！',
  '该红包已被领取！': '该红包已被领取！',
  '签到功能暂未开放~': '签到功能暂未开放~',
  '您今天已经签过到啦！': '您今天已经签过到啦！',
  '签到成功！': '签到成功！',
  '提现功能暂未开启！': '提现功能暂未开启！',
  '当前时间段内不能进行提现！': '当前时间段内不能进行提现！',
  '请选择银行卡！': '请选择银行卡！',
  '银行卡错误，请联系客服核查！': '银行卡错误，请联系客服核查！',
  '提现金额不能为空。': '提现金额不能为空。',
  '提现类型不能为空。': '提现类型不能为空。',
  '该提现方式已暂停使用，请选择其他的提现方式！': '该提现方式已暂停使用，请选择其他的提现方式！',
  '您暂未绑定银行卡，请绑定后再试！': '您暂未绑定银行卡，请绑定后再试！',
  '您所提交的提现类型暂未绑定，请绑定后再试！': '您所提交的提现类型暂未绑定，请绑定后再试！',
  '产品信息错误！': '产品信息错误！',
  '该产品已暂停参与购买，请购买其他产品！': '该产品已暂停参与购买，请购买其他产品！',
  该产品秒杀活动暂未开始: '该产品秒杀活动暂未开始',
  该产品秒杀活动已结束: '该产品秒杀活动已结束',
  '购买金额不能为空！': '购买金额不能为空！',
  '当前会员等级不能进行购买此产品！': '当前会员等级不能进行购买此产品！',
  '您的余额不足，请前往充值！': '您的余额不足，请前往充值！',
  不能低于起始购买金额: '不能低于起始购买金额',
  不能高于封顶购买金额: '不能高于封顶购买金额',
  已超过该产品可购买额: '已超过该产品可购买额',
  已经超过改产品可购买次数: '已经超过改产品可购买次数',
  '认购成功！': '认购成功！',
  '购买失败,请联系客服处理！': '购买失败,请联系客服处理！',
  '今天您已经答过题啦！': '今天您已经答过题啦！',
  '题目不能为空！': '题目不能为空！',
  '该题目您已经作答！': '该题目您已经作答！',
  '回答错误！': '回答错误！',
  完成答题: '完成答题',
  '回答正确！': '回答正确！',
  '该商品暂时无法兑换！': '该商品暂时无法兑换！',
  '请输入收货信息！': '请输入收货信息！',
  '兑换失败，请联系在线客服！': '兑换失败，请联系在线客服！',
  '该商品已被兑换完,请兑换其他商品！': '该商品已被兑换完,请兑换其他商品！',
  '您的经验值不足,无法进行兑换！': '您的经验值不足,无法进行兑换！',
  '已超过该商品可兑换次数！': '已超过该商品可兑换次数！',
  '兑换成功！': '兑换成功！',
  '该订单无法确认收货！': '该订单无法确认收货！',
  '确认收货成功！': '确认收货成功！',
  '确认收货失败,请联系客服处理！': '确认收货失败,请联系客服处理！',
  注册功能暂未开放: '注册功能暂未开放',
  当前IP注册量已达到最大值: '当前IP注册量已达到最大值',
  '您当前的手机型号只能注册一个账号！': '您当前的手机型号只能注册一个账号！',
  '手机号码不能为空。': '手机号码不能为空。',
  '密码不能为空。': '密码不能为空。',
  '类型不能为空。': '类型不能为空。',
  '验证码不能为空。': '验证码不能为空。',
  '机型不能为空。': '机型不能为空。',
  '邀请码不能为空。': '邀请码不能为空。',
  '重复密码不能为空。': '重复密码不能为空。',
  '密码只能包含至少6个字符。': '密码只能包含至少6个字符。',
  '密码只能包含至多20个字符。': '密码只能包含至多20个字符。',
  验证码错误: '验证码错误',
  找不到推广员: '找不到推广员',
  '手机唯一标识符不能为空。': '手机唯一标识符不能为空。',
  '组别不能为空。': '组别不能为空。',
  '操作繁忙，请您联系在线客服！': '操作繁忙，请您联系在线客服！',
  '用途不能为空。': '用途不能为空。',
  请不要频繁发送短信: '请不要频繁发送短信',
  短信发送失败: '短信发送失败',
  发送成功: '发送成功',
  退出成功: '退出成功',
  退出失败: '退出失败',
  '充值功能暂未开启！': '充值功能暂未开启！',
  账号或者密码错误: '账号或者密码错误',
  '重复密码的值必须等于"密码"。': '重复密码的值必须等于"密码"。',
  '请勿频繁操作！': '请勿频繁操作！',
  '该金额无法进行充值！': '该金额无法进行充值！',
  最低提现金额是: '最低提现金额是{0}',
  最高充值金额是: '最高充值金额是{0}',
  最低充值金额是: '最低充值金额是{0}',
  '标识符是无效的。': '标识符是无效的。',
  '该证件号码已存在！': '该证件号码已存在！',
  '积分不足,签到可获得积分！': '积分不足,签到可获得积分！',
  '领取成功！': '领取成功！',
  '确定新密码应该包含至少6个字符。': '确定新密码应该包含至少6个字符。',
  '旧密码错误!': '旧密码错误!',
  您输入的俩次安全密码不一致: '您输入的俩次安全密码不一致',
  '操作失败，请您联系在线客服！': '操作失败，请您联系在线客服！',
  恭喜您获得: '恭喜您获得',
  '此手机号码已存在。': '此手机号码已存在。',
  请上传凭证: '请上传凭证',
  '卡号应该包含至少16个字符。': '卡号应该包含至少16个字符。',
  '上传成功！': '上传成功！',
  '该银行卡卡号已存在！': '该银行卡卡号已存在！',
  '【返佣】推荐用户成功，获得奖金': '【返佣】推荐用户成功，获得奖金',
  '【系统】完成实名认证获得奖金': '【系统】完成实名认证获得奖金',
  '【红包】系统赠送获得奖金': '【红包】系统赠送获得奖金',
  '【系统】签到赠送积分': '【系统】签到赠送积分',
  '【系统】签到赠送奖金': '【系统】签到赠送奖金',
  '【返佣】推荐用户达到要求，获得奖金': '【返佣】推荐用户达到要求，获得奖金',
  '【订单】下级购买产品获得积分': '【订单】下级购买产品获得积分',
  '【红包】购买活动产品，获得奖金': '【红包】购买活动产品，获得奖金',
  '【红包】购买活动产品，获得返现': '【红包】购买活动产品，获得返现',
  '【订单】购买产品，扣除余额': '【订单】购买产品，扣除余额',
  '【订单】购买产品赠送积分': '【订单】购买产品赠送积分',
  '【返佣】下级购买产品，获得一级返佣': '【返佣】下级购买产品，获得一级返佣',
  '【返佣】下级购买产品，获得二级返佣': '【返佣】下级购买产品，获得二级返佣',
  '【返佣】下级购买产品，获得项目返佣奖励': '【返佣】下级购买产品，获得项目返佣奖励',
  '【系统】回答正确赠送积分': '【系统】回答正确赠送积分',
  '【系统】回答正确赠送奖金': '【系统】回答正确赠送奖金',
  '【兑换】积分兑换商品': '【兑换】积分兑换商品',
  '【兑换】积分兑换红包成功，获得奖金': '【兑换】积分兑换红包成功，获得奖金',
  '【退本】购买产品周期结束，退还本金': '【退本】购买产品周期结束，退还本金',
  '【红包】购买活动产品周期完成，获得奖金': '【红包】购买活动产品周期完成，获得奖金',
  '【红包】购买活动产品周期完成，获得返现': '【红包】购买活动产品周期完成，获得返现',
  '【收益】购买产品，获得收益': '【收益】购买产品，获得收益',
  '【系统】注册赠送奖金': '【系统】注册赠送奖金',
  '【系统】注册赠送积分': '【系统】注册赠送积分',
  '【系统】余额充值': '【系统】余额充值',
  '【系统】充值赠送': '【系统】充值赠送',
  '【系统】提现扣除余额': '【系统】提现扣除余额',
  '【系统】提现被拒返款': '【系统】提现被拒返款',
  '【系统】订单支付': '【系统】订单支付',
  '【红包】邀请好友领取系统红包': '【红包】邀请好友领取系统红包',
  '【系统】晋升VIP等级，领取系统红包': '【系统】晋升VIP等级，领取系统红包',
  '【后台】管理员操作扣除余额': '【后台】管理员操作扣除余额',
  '【后台】管理员操作添加余额': '【后台】管理员操作添加余额',
  '【后台】管理员操作扣除积分': '【后台】管理员操作扣除积分',
  '【后台】管理员操作添加积分': '【后台】管理员操作添加积分',
  '卡号不能为空。': '卡号不能为空。',
  '开户行不能为空。': '开户行不能为空。',
  '登录已过期，请重新登录': '登录已过期，请重新登录',
  充值: '充值',
  提现: '',
  团队报表: '团队报表',
  账户明细: '账户明细',
  实名认证: '实名认证',
  积分商城: '积分商城',
  我的消息: '我的消息',
  联系客服: '联系客服'
}
